import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../Firebase/firebase";
import { IApiSetUserFirebaseTokenParams, setUserFirebaseToken } from "../ApiCall/users";

import { setDefaultAdminMetas } from '../utils/metaTags';
import { getStorageLeagueId } from "../utils/localStorage";

import ChangeAdminLeague from "../League/ChangeAdminLeague";
import AdminMenu from "../Menu/AdminMenu";
import LoaderInfo from "../Generic/LoaderInfo";

function AdminApp() {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const [user, loading] = useAuthState(auth);
  const [dataRetrieved, setDataRetrieved] = useState<boolean>(false);
  
  useEffect(() => {
    if( getStorageLeagueId() === 0 && location !== '/admin/dashboard'){
      navigate('/admin/dashboard');
    }
  },[location, navigate])
  
  useEffect(() => {
    if (loading) return;
    if (user) {
      user.getIdToken().then(token=>{
        window.localStorage.setItem('userToken', token);

        const paramsSetUserFirebaseToken: IApiSetUserFirebaseTokenParams = {
          email: user.email || '',
          token: token,
        }
        setUserFirebaseToken(paramsSetUserFirebaseToken)
          .then(response => {
            if( location === '/admin/login'){
              navigate("/admin/dashboard");
            }
          })
          .catch(error => {
            window.localStorage.clear();
          })
          .then(() => {
            setDataRetrieved(true);
          })
      })
    }
    else {
      window.localStorage.clear();
      navigate("/admin/login");
    }
  }, [user, loading, navigate, location]);

  setDefaultAdminMetas();
  return (
    <>
      <LoaderInfo
        hasWrapper={true}
        isLoading={dataRetrieved}
      />
      
      {dataRetrieved ? (
        <>
          <AdminMenu />
          <ChangeAdminLeague />
          <Outlet />
        </>
      ) : ''}
    </>
  )
}
export default AdminApp;
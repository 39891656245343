import CreateLeague from "../League/CreateLeague";
import CreateSeason from "../Seasons/CreateSeason";

function LeagueManager() {

  return (
    <>
      <CreateLeague />
      <CreateSeason />
    </>
  );
}

export default LeagueManager;